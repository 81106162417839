import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../store/hooks"
import { HeadlineCard } from "./HeadlineCard"
import  "./HeadlineCards.css"
import { SceneCardManager } from "../SceneCards/SceneCardManager"
import { Scene } from "../../store/scenes"

export const HeadlineCardsManager: React.FC = () => {

    const { scenes } = useAppSelector(state => state.scene);
    const [carouselScenes, setCarouselScenes] = useState<Scene[]>([]);

    useEffect(() => {
        let shuffled = shuffleArray(scenes).slice(0,4)
        setCarouselScenes(shuffled)
    }, [])

    function shuffleArray(array: Scene[]) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; 
        }
        return shuffledArray;
    }
    
    return (
        <div id="Featured" style={{marginTop: '20px', padding: '10px', color: 'white'}}>
            <h2 className="heading">Featured</h2>
            <div className="headline-card-manager">
                {
                    carouselScenes.map((scene,index) => (
                        <HeadlineCard key={scene.name} scene={scene} /> 
                    ))
                }
            </div>
            <hr style={{ height: '1px', backgroundColor: 'gray', border: 'none',marginTop: '80px', width: '80%' }}/>
            <SceneCardManager />
        </div>
    )
}
