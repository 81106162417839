import { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import axios from "axios"
import { RPM } from "@vc/rpm-custom"
import { setCookieDoesntMatchFlag, setPlayer, setCanJumpIn, setRpmBearerToken, setRpmId, setAvatarId } from "../store/appUser"
import { setSelectedPlayer } from "../store/party"
import { VRMUpdateModal } from "./VRM/VRMUpdateModal"

type Props = {
    sendMessage: Function
}

export interface CSSPropertiesWithVars extends React.CSSProperties {
    '--gradient-color'?: string;
}

export const Profile: React.FC<Props> = ({sendMessage}) => {

    const { player, update, needsLoginFlag, cookieDoesntMatchFlag, rpmBearerToken, rpmId, avatarId } = useAppSelector(state  => state.appUser)
    const { className } = useAppSelector(state =>  state.scene)
    const [open, setOpen] = useState(false)
    const [toggle, setToggle] = useState(player.glbUrl?.includes(".vrm") ? true : false)
    const dispatch = useAppDispatch()
    
    const playerRef = useRef(player)
    const RPMConfig = {
        needsLoginFlag: needsLoginFlag,
        cookieDoesntMatchFlag: cookieDoesntMatchFlag,
        rpmBearerToken: rpmBearerToken,
        rpmId: rpmId,
        avatarId: avatarId,
        currentClassName: className,
        setCookieDoesntMatchFlag: setCookieDoesntMatchFlag,
        setPlayer: setPlayer,
        setCanJumpIn: setCanJumpIn,
        setSelectedPlayer: setSelectedPlayer,
        setRpmBearerToken: setRpmBearerToken,
        setRpmId: setRpmId,
        setAvatarId: setAvatarId,
        dispatch: dispatch,
    }

    const backPackConfig = {
        open:open,
        setOpen: setOpen,
        sendMessage: sendMessage
    }

    useEffect(() => {
        playerRef.current = player
    },[player])



    useEffect(() => {
        if(playerRef.current.glbUrl){
            if(playerRef.current.glbUrl.endsWith(".vrm") && playerRef.current.avatarProfilePic){
                const imgElement = document.getElementById("profile-image") as HTMLImageElement;
                if (imgElement) {
                  imgElement.src = playerRef.current.avatarProfilePic;
                  imgElement.style.transform = 'scale(1)'
                }
            } else {
                loadImage(playerRef.current.glbUrl?.replace(".glb", ".png"))
               
            }
        }
   
    },[update])

    const loadImage = async (url: string) => {
        try {
          const response = await axios.get(url, {
            responseType: 'blob',
            headers: {
              'Cache-Control': 'no-cache'
            }
          });
      
          const imageUrl = URL.createObjectURL(response.data);
      
          const imgElement = document.getElementById("profile-image") as HTMLImageElement;
          if (imgElement) {
            imgElement.src = imageUrl;
            imgElement.style.transform = 'scale(2.2) translateY(20%)'
            
          }

        } catch (error) {
          console.error('Error loading image:', error);
        }
    };

    return(
        <div>
            <button
                className="profile-button"
                onClick={() => setOpen(!open)}
            >
                <div className="image-container-online" style={
                {
                    '--gradient-color': "var(--primary-color)",
                    borderRadius: '50%', 
                    position: 'absolute', 
                    padding: '10px',
                    background: 'linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%)',
                    right: `16px`,
                    top: '16px',
                } as CSSPropertiesWithVars
            }>
            <div className="profile-inner-image-container">
                            <img 
                                className="profile-image"
                                id="profile-image"
                                alt=""
                            /> 
                            </div>
            
            </div>
            </button>
            {
                toggle ? <VRMUpdateModal modal={true} currentClassName="bob" setCanJumpIn={() => console.log("Hello")} setOpen={setOpen} open={open}/> :  <RPM
                                                                            player={player}
                                                                            config={RPMConfig}
                                                                            backpack={true}
                                                                            backPackConfig={backPackConfig}
                                                                            avatarBackgroundColour={'rgb(36, 30, 54)'}
                                                                            titles={false}
                                                                        />
            }
           {
                open && (
                    <button className="join-button" style={{position: 'absolute', left: '66%', top: '78.5vh'}} onClick={() => setToggle(!toggle)}>Toggle Picker</button>
                )
           }
        </div>
       
    )
}