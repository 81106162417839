import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import "./CustomStyles/Chat.css";
import "./CustomStyles/Debug.css";
import "./CustomStyles/RPMStyles.css";
import { Fragment, useEffect } from "react";
import { UnityClient } from './components/UnityClient';
import { InboundLinkRouting } from './components/InboundLinkRouting';
import WelcomePage from './components/LandingPage/WelcomePage';
import { AvatarPage } from './components/AvatarPage/AvatarPage';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { LoginPage } from './components/LandingPage/LoginPage';
import ProtectedRoute from './Utils/ProtectedRoute';
import { setShowAlert } from './store/appUser';
import { AlertModal } from './components/AlertModal';
import { setConnected, setWalletAddress } from './store/web3';
import { ScenePage } from './components/ScenePages/ScenePage';

const App: React.FC = () => {

  const { scenes } = useAppSelector(state => state.scene)
  const { connected, walletAddress } = useAppSelector(state => state.web3)
  const { showAlert } = useAppSelector(state => state.appUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts: unknown) => {
        if (Array.isArray(accounts) && accounts.every((acc) => typeof acc === 'string')) {
          if (accounts[0] !== walletAddress && accounts.length > 1) {
            dispatch(setConnected(false))
            dispatch(setShowAlert(true))
          }
          if (accounts.length === 0) {
            dispatch(setConnected(false))
          }
        }
        else {
          console.warn("Unexpected accounts format:", accounts);
        }
      };

      // Register the event listener
      window.ethereum?.on("accountsChanged", handleAccountsChanged);

      // Cleanup event listener
      return () => {
        if (window.ethereum) {
          window.ethereum.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
        };
      }
    }
  }, [dispatch, walletAddress]);

  return (
    <Fragment>
      <AlertModal open={showAlert} />
      <Routes>
        <Route
          path="/"
          element={
            <LoginPage />}
        />
        <Route
          path="/home"
          element={
            <WelcomePage />}
        />
        {
          scenes.map(scene => {
            return <Route
              path={`/mv/live/${scene.className}`}
              element={
                <InboundLinkRouting scene={scene} scenePage={true}  getProfile={false} rpmRefresh={false}/>
              }
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
              path={`${scene.url}`}
              element={
                <ProtectedRoute loggedIn={connected} element={<InboundLinkRouting scene={scene} scenePage={false}  getProfile={true} rpmRefresh={false} />} />}
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
              path={`${scene.url}/:id`}
              element={
                <ProtectedRoute loggedIn={connected} element={<InboundLinkRouting scene={scene} scenePage={false}  getProfile={true} rpmRefresh={false}/>} />}
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
              path={`${scene.infoPageUrl}`}
              element={
                <ScenePage scene={scene} />
              }
            />
          })
        }
        {
          scenes.map(scene => {
            return <Route
                    key={"/mv/rpm/" + scene.className}
                    path={"/mv/rpm/" + scene.className}
                    element={
                      <InboundLinkRouting scene={scene} scenePage={false} getProfile={true}  rpmRefresh={true}/>
                    }
                    />
          })
        }
      </Routes>
    </Fragment>
  );
}

export default App;
