import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setAvatarId, setCanJumpIn, setCookieDoesntMatchFlag, setNeedsLoginFlag, setPlayer, setRpmBearerToken, setRpmId, user } from "../store/appUser";
import { Scene, setClassName, setCode, setData, setFramework, setIndex, setLoader, setModuleId, setName, setSceneUrl, setStreamingAssets } from "../store/scenes";
import { ScenePage } from "./ScenePages/ScenePage";
import { useSearchParams } from 'react-router-dom';
import { RPM } from "@vc/rpm-custom";
import { setSelectedPlayer } from "../store/party";
import { VRMPicker } from "./VRM/VRMPicker";
import { VRM } from "./VRM/VRM";
import { AvatarSwitcher } from "./AvatarSwitcher";

export const globalIsHost = {
    isHost: false
} 

type Props = {
    scene: Scene,
    scenePage: boolean,
    getProfile: boolean,
    rpmRefresh: boolean
}

export const InboundLinkRouting: React.FC<Props> = ({scene, scenePage, getProfile, rpmRefresh}) => {

    const {id} = useParams<RouteParams>();
    const { player, canJumpIn, needsLoginFlag, cookieDoesntMatchFlag, rpmBearerToken, rpmId,avatarId } = useAppSelector(state => state.appUser)
    const { className } = useAppSelector(state => state.scene)
    const { connected } = useAppSelector(state =>  state.web3)
    const [hasCookie, setHasCookie] = useState(false);
    const [readyForRPM, setReadyForRPM] = useState(false);
    const [sceneLoaded, setSceneLoaded] = useState(false);
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    searchParams.set('show_call_ui', 'true');
    const queryString = searchParams.toString();

    const RPMConfig = {
        needsLoginFlag: needsLoginFlag,
        cookieDoesntMatchFlag: cookieDoesntMatchFlag,
        rpmBearerToken: rpmBearerToken,
        rpmId: rpmId,
        avatarId: avatarId,
        currentClassName: className,
        setCookieDoesntMatchFlag: setCookieDoesntMatchFlag,
        setPlayer: setPlayer,
        setCanJumpIn: setCanJumpIn,
        setSelectedPlayer: setSelectedPlayer,
        setRpmBearerToken: setRpmBearerToken,
        setRpmId: setRpmId,
        setAvatarId: setAvatarId,
        dispatch: dispatch,
    }
    
    useEffect(() => {
        dispatch(setLoader(scene.loader))
        dispatch(setData(scene.data))
        dispatch(setFramework(scene.framework))
        dispatch(setCode(scene.code))
        dispatch(setStreamingAssets(scene.streamingAssests))
        dispatch(setName(scene.name))
        dispatch(setModuleId(scene.moduleId))
        dispatch(setClassName(scene.className))
        dispatch(setSceneUrl(scene.url))
        dispatch(setIndex(scene.index))
        setSyle()
        setSceneLoaded(true)
    },[])

    const setSyle = () => {
        document.documentElement.style.setProperty('--background-avatar', `url(${scene.style.background})`);
        document.documentElement.style.setProperty('--primary-color', scene.style.primary_color);
        document.documentElement.style.setProperty('--secondary-color', scene.style.secondary_color);
        document.documentElement.style.setProperty('--accent-color', scene.style.accent_color);
        document.documentElement.style.setProperty('--highlight-color', scene.style.highlight_color);
        document.documentElement.style.setProperty('--error-color', scene.style.error_color);
        document.documentElement.style.setProperty('--background-avata', scene.style.text);
        document.documentElement.style.setProperty('--text-color', scene.style.text);
    }

    useEffect(()=>{
        if(canJumpIn){
            setHasCookie(true)
        }
    },[])

    useEffect(() => {
        if(player.glbUrl){
            setHasCookie(true)
        }
    },[])

    useEffect(() => {
        setFlags(player)
    }, [player]);


    function setFlags(data: user){
        console.log("Set Flags Data: ", data)
        // if their isnt a glb or cookie set flags to false
        if (data.glbUrl === null && Cookies.get("AvatarGlb") === undefined) {
            dispatch(setCookieDoesntMatchFlag(false))
            dispatch(setNeedsLoginFlag(false))
            //setHasCookie(false)
        } else {
            // otherwise set tokens from cookies for loading avatar and getting assets
            //setHasCookie(false)
            dispatch(setRpmId(Cookies.get("rpmId")))
            dispatch(setRpmBearerToken(Cookies.get("rpmToken")))
            
            // if the avatar was created with rpm account make flag true to show alert
            if(player.createdByRpmAccount){
                dispatch(setNeedsLoginFlag(true))
            }
            if(!data.glbUrl?.endsWith(".vrm")){
                if(Cookies.get("AvatarGlb") !== undefined){
                    if (player.glbUrl !== Cookies.get("AvatarGlb")){
                        // otherwise if the cookie and avatar dont match and created with rpm is false set cookies dont match flag
                        console.log("Glb? : " + player.glbUrl !== null ? true : false)
                        dispatch(setCookieDoesntMatchFlag(player.glbUrl !== null ? true : false))
                    }
                } else if(data.glbUrl !== null && Cookies.get("AvatarGlb") === undefined){
                    dispatch(setCookieDoesntMatchFlag(true))
                }
            }
        }

        
        if (data.glbUrl){
            dispatch(setAvatarId(data.glbUrl.replace(".glb", "").split("/").pop()))
        }
    }


    if(id === hostId){
        globalIsHost.isHost = true
    }

    if(scenePage){
        return(
            <>
               {connected ?  <UnityClient params={queryString} scene={scene}/> : <ScenePage scene={scene}/>}
            </>
        )
    }
    if(rpmRefresh){
        return(
            <>
                {player.displayName !== null && hasCookie ?  
                //  <RPM
                //         player={player}
                //         config={RPMConfig}
                //         backpack={false}
                //         avatarBackgroundColour={'rgb(36, 30, 54)'}
                //         titles={false}/>
                        
                // <VRM setCanJumpIn={setHasCookie} currentClassName={scene.className}/>
                <AvatarSwitcher scene={scene} setHasCookie={setHasCookie}/>
                            :  
                            <AvatarPage/> }
                </>
        )
    }

    return(
        <>
        {sceneLoaded && (
           hasCookie ? <UnityClient  params={queryString} scene={scene}/> : connected ? ( readyForRPM ?  
        //    <RPM
        //     player={player}
        //     config={RPMConfig}
        //     backpack={false}
        //     avatarBackgroundColour={'rgb(36, 30, 54)'}
        //     titles={false}/>
        <AvatarSwitcher scene={scene} setHasCookie={setHasCookie}/>
            : <AvatarPage/>)  : <ScenePage scene={scene}/>
       )}
   </>
    )
}