import React from "react";
import { useAppSelector } from "../../../store/hooks";
import { SceneCard } from "../../SceneCards/SceneCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

export const LandingTilesCarousel: React.FC = () => {
  const { scenes } = useAppSelector((state) => state.scene);
    const history = useNavigate()
  return (
    <div className="slider-container" style={{ margin: "100px 0", height: "120%" }}>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={20}
        slidesPerView={5}
        loop={true}
        direction="horizontal"
        autoplay={{
          delay: 0, 
          disableOnInteraction: false,
          pauseOnMouseEnter: true, 
          reverseDirection: false,
        }}
        speed={2000}
        grabCursor={true}
        
      >
        {scenes.map((scene) => (
          <SwiperSlide key={scene.name}>
            <SceneCard scene={scene} carousel={true} className="scene-card-carousel" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div style={{
                display: 'flex',          
                justifyContent: 'center',
                marginTop: '20px',       
                width: '100%'
            }}>
          <button 
            className='join-button'
            onClick={() => history('/home')}
            >
            Explore Scenes
            </button>
          </div>
          <div className="overlay-tile-carousel"></div>
    </div>
  );
};
