import { useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { AboutSection } from "./AboutSection";
import { LandingTilesCarousel } from "./components/LandingTilesCarousel";
import { Footer } from "./Footer";

export const LoginPage:React.FC = () => {
    
    const history = useNavigate()
    

    return(
        <div>
          <Header/>
        <div className='video-container'>
            <div className='text-container-landing'>
            <h1 style={{ fontSize: '100px', textAlign: 'center'}}>Unleash Your Imagination</h1>
            <p className='landing-tag'>Explore, Play, and Connect in Our Virtual Worlds</p>
            <div style={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",  
                gap: "100px", 
                marginTop: "20px"
            }}>
                 <div className="login-elements-container" style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                <img  src="/images/metamask.svg" alt="metamask" width="100" style={{margin: '0 auto'}} />
                <button className="join-button" onClick={() => history("/home")}>
                   Enter Site
                </button>
            </div>
            </div>
            
            </div>
            <video autoPlay muted loop preload='auto' style={{ zIndex: 0, height: '100vh' }}>
            <source src='/video/landing.mp4' type='video/webm' />
            Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
      </div>
      <LandingTilesCarousel />
          <AboutSection title='What We Do' 
            description='Welcome to a unique digital platform where you can explore immersive Unity experiences, 
            all from the comfort of your browser. Dive into a variety of interactive environments, 
            from vibrant sports bars and lively music venues to engaging gaming rooms. 
            Whether you are here to compete in virtual sports, enjoy live music, 
            or connect with friends in dynamic social spaces, our platform brings the excitement of real-world entertainment to your screen. 
            Discover endless possibilities, meet new people, and enjoy a fresh way to experience entertainment online—anytime, anywhere.' 
            imagePath='/images/hexMan.png'
            flip={true}
            cssClassName="about-section"
            />
            <div className="about-sections-container">
                <AboutSection title='Sports' 
                description='Step into the thrill of live sports from the comfort of your own home. 
                Our virtual sports venues bring the excitement of the game right to your screen, whether you are a fan of fast-paced 
                basketball, strategic tennis, or heart-pounding soccer. Feel the energy of the crowd, compete with others in real-time, 
                and experience the adrenaline of your favorite sports in a fully immersive digital environment. It is not just a game; 
                it is an experience that connects you with fellow fans and players from around the world, all in one virtual space.' 
                imagePath='/images/sport.png'
                flip={false}
                cssClassName="about-variation"
                />
                <AboutSection title='Music' 
                description="Immerse yourself in the rhythm of live music like never before. Our virtual music venues host an array of 
                live performances, from indie bands to world-class DJs, allowing you to enjoy a concert atmosphere without leaving 
                your home. Whether you're into jazz, rock, or electronic beats, you'll find yourself front and center, 
                feeling the energy of the crowd and the passion of the performers. Connect with music lovers globally, 
                discover new artists, and share the joy of live music in a social, interactive setting that brings the stage 
                right to you"
                imagePath='/images/music.png'
                flip={false}
                cssClassName="about-variation"
                />
            </div>
            
          <Footer additionalClasses={''} home={false}/>
      </div>      
    )
}