import { createSlice} from "@reduxjs/toolkit";

interface web3State {
    walletAddress: string,
    signedToken: string,
    connected: boolean
}
const initialState: web3State = {
    walletAddress: "",
    signedToken: "",
    connected: false
}
export const web3Slice = createSlice({
    name:"web3",
    initialState,
    reducers: {
        setWalletAddress: (state, action) => {
            state.walletAddress = action.payload
        },
        setSignedToken: (state, action) => {
            state.signedToken = action.payload
        },
        setConnected: (state, action) => {
            state.connected = action.payload
        }
    }
});

export const { setWalletAddress, setSignedToken, setConnected } = web3Slice.actions;

export default web3Slice.reducer;