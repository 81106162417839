import React, { useState } from "react";
import { Scene } from "../../store/scenes";
import { OnlineTracker } from "../LandingPage/components/OnlineTracker";
import { useNavigate } from "react-router-dom";

type Props = {
    scene: Scene,
    carousel: boolean,
    className: string
}

export const SceneCard: React.FC<Props> = ({ scene, carousel,className }) => {
    const [isHovered, setIsHovered] = useState(false);
    const history = useNavigate()
    return (
        <div
            className={className}
            style={{ position: 'relative', overflow: 'hidden', backgroundImage: `url(${scene.image})` }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => history(scene.infoPageUrl)}
        >
            {
                carousel ? null : <OnlineTracker count={10} className="scene-card-tracker"/>
            }
            
            <div className="video-container-card" style={{ display: isHovered ? 'block' : 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
                {isHovered && (<video className="hover-video" autoPlay muted loop >
                    <source src={scene.video} type="video/webm" />
                    Your browser does not support the video tag.
                </video>)}
            </div>
            {
                scene.logo ? <img alt={scene.name} src={scene.logo} height={scene.name ==="Nugs" ? 50 : 100} style={{ position: isHovered ? 'absolute' : 'relative', top: isHovered ? 30 : 0, zIndex: 2 }} /> : <h1 className={scene.className} style={{ color: 'white', backgroundColor: isHovered ? 'transparent' : 'black', padding: isHovered  ? '1px' : '5px', position: isHovered ? 'absolute' : 'relative', top: isHovered ?20 : 0, zIndex: 2 }}>
                    {scene.name}
                </h1>
            }

          { !carousel && ( <div style={{
                textAlign: 'left',
                display: isHovered ? 'block' : 'none',
                backgroundColor: '#666666',
                color: 'white',
                position: 'relative',
                zIndex: 2,
                marginTop: 'auto',
                fontSize: 'small',
                padding: '5px 15px'
            }}>
                
                <p style={{ fontSize: '0.7rem', color: '#dddddd', padding: '2px 0', margin: 0 }}>{scene.category}</p>
                <p style={{ color: 'white', padding: 0, margin: 0 }}>{scene.blurb}</p>
            </div>)}
        </div>
    )
}
