type Props = {
    title: string;
    description: string;
    imagePath: string | null;
    flip: boolean;
    cssClassName:string; 
  };
  
  export const AboutSection: React.FC<Props> = ({
    title,
    description,
    imagePath,
    flip,
    cssClassName
  }) => {
    return (
      <div className={cssClassName}>
        <h3 className="heading" style={{textAlign: flip ? "center" : "left"}}>{title}</h3>
        {
          flip ? <div className="content">
          {imagePath ? <img src={imagePath} alt="About" /> : null}
          <p>{description}</p>
        </div> : <div className="content">
          <p>{description}</p>
          {imagePath ? <img src={imagePath} alt="About" /> : null}
        </div>
        }
      </div>
    );
  };
  