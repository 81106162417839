import { useNavigate } from 'react-router-dom'
import './styles/Footer.css'

type Props = {
    additionalClasses: string
    home:boolean
}
export const Footer: React.FC<Props> = ({additionalClasses,home}) => {

    const history = useNavigate()

    return(
        <>
        <div className={`footer ${additionalClasses}`}>
          <hr style={{ height: '1px', backgroundColor: 'gray', border: 'none', width: '100%', margin: 0 }} />
          <div className="footer-content">
            <div className="footer-left">
              {home ? (
                <div className="footer-links" onClick={() => history("/")}>
                  Home
                </div>
              ) : null}
              <div className="footer-links" onClick={() => history("/about")}>
                About
              </div>
              <div className="footer-links">Contact</div>
              <div className="footer-links" onClick={() => history("/FAQ")}>
                FAQ
              </div>
            </div>
            <div className="copy">
              &copy; <span id="year"></span> All rights reserved.
            </div>
            <div className="footer-socials">
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src="/images/socials/instagram.png" alt="Instagram" height={25} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src="/images/socials/linkedIn.svg" alt="LinkedIn" height={25} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src="/images/socials/x.png" alt="Tiktok" height={25} />
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <img src="/images/socials/facebook.png" alt="Facebook" height={25} />
              </a>
            </div>
          </div>
        </div>
      </>
      
    )
}