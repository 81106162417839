import React from "react"
import { useAppSelector } from "../../store/hooks"
import  "./SceneCards.css"
import { SceneCard } from "./SceneCard"
import { Scene } from "../../store/scenes"


export const SceneCardManager: React.FC = () => {
    const { scenes } = useAppSelector(state => state.scene);
    
    function shuffleArray(array: Scene[]) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; 
        }
        return shuffledArray;
    }

    return (
        <div id="Scenes" style={{marginTop: '20px', padding: '10px', color: 'white'}}>
            <h2 className="heading">All Scenes</h2>
            <div className="scene-card-manager">
                {
                    shuffleArray(scenes).map(scene => (
                        <SceneCard key={scene.name} scene={scene} carousel={false} className="scene-card"/>
                    ))
                }
            </div>
        </div>
    )
}
