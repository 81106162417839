import React, { useState } from "react";
import { Scene } from "../../store/scenes";
import { OnlineTracker } from "../LandingPage/components/OnlineTracker";

type Props = {
    scene: Scene
}

export const HeadlineCard: React.FC<Props> = ({scene}) => {

    const [isHovered, setIsHovered] = useState(false);

    return (
        <a 
            className="headline-card" 
            href={window.location.origin + scene.url.toLowerCase()}
            style={{ position: 'relative', overflow: 'hidden', backgroundImage: `url(${scene.image})` }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            target="_self" 
            >
            <div style={{ display: isHovered ? "block":"none", position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
                { isHovered && (<video className="hover-video" autoPlay muted loop >
                    <source src={scene.video}  type="video/webm"/>
                    Your browser does not support the video tag.
                </video>)}
            </div>
            <OnlineTracker count={10} className=""/>
        {
            scene.logo ?  <img alt={scene.name} src={scene.logo} height={50} style={{position: 'relative', zIndex: 2, bottom:isHovered ? -130 : 0 }}/> : <h1 className={scene.className} style={{ color: 'white', backgroundColor: isHovered ? 'transparent':'black', padding: '5px', position: 'relative', zIndex: 2, bottom:isHovered ? -130 : 0 }}>{scene.name}</h1>
        }
        </a>
    )
}
