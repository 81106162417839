import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

export const VideoCarousel: React.FC = () => {
  
  const {scenes} = useAppSelector(state => state.scene)
  const history = useNavigate()
  const settings = {
    dots: true,
    infinite: scenes.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    appendDots: (dots: any) => (
      <div
        style={{
          backgroundColor: "#000",
          borderRadius: "10px",
          padding: "10px"
        }}
      >
        <ul style={{ margin: "0px", color: "white" }}> {dots} </ul>
      </div>
    ),
    autoplay: true,
    autoplaySpeed: 5000
  };

  return (
    <Slider {...settings}>
      {scenes.filter((scene) => scene.carousel).map((scene) => {
        return (
        <div key={scene.name} className="video-container">
            <div className="text-container">
              {scene.logo ? (
                <img alt={scene.name} src={scene.logo} height={100} />
              ) : (
                <h1 className={`video-title ${scene.className}`}>{scene.name}</h1>
              )}
              <h4 style={{color: 'grey', margin: 0}}>{scene.category}</h4>
              <p className="video-description">{scene.blurb}</p>
              <button className="join-button" style={{marginTop: '20px'}} onClick={() => history(scene.infoPageUrl)}>
                Join Now
              </button>
            </div>
            <video key={scene.video} autoPlay muted loop preload="auto" style={{ zIndex: 0 }}>
              <source src={scene.video} type="video/webm" />
              Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
          </div>
        );
      })}
    </Slider>
  );
};
