import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useSDK } from "@metamask/sdk-react";
import { setConnected, setSignedToken, setWalletAddress } from '../../store/web3';
import { setPlayer } from '../../store/appUser';

interface Props {
  contain?: () => Window;
}

const drawerWidth = 240;
const boxStyle: React.CSSProperties = { display: 'flex' };

export const Header: React.FC<Props> = ({ contain }) => {

  const { scenes } = useAppSelector(state => state.scene);
  const { connected } = useAppSelector(state => state.web3)
  const { sdk } = useSDK();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDisconnect = async () => {
    try {
      const accounts = await sdk?.terminate().then(() => {
        dispatch(setWalletAddress(""))
        dispatch(setSignedToken(""))
        dispatch(setConnected(false))
        history("/")
      });
    } catch (err) {
      console.warn("Failed to connect or sign message:", err);
    }
  }

  const drawer = (
    <div onClick={handleDrawerToggle}>
      <h3 style={{ textAlign: 'center' }}>Scenes</h3>
      <hr style={{ width: '80%', height: '1px', backgroundColor: 'gray', border: 'none' }} />
      <List>
        {scenes.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <a href={window.location.origin + item.url.toLowerCase()}>{item.icon} {item.name}</a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = contain !== undefined ? () => contain().document.body : undefined;

  const connectAndSign = async () => {
    try {
      const accounts = await sdk?.connect();
      if (!accounts || accounts.length === 0) {
        console.warn("No accounts found");
        return;
      }
      console.log(accounts)
      const walletAddress = accounts[0];
      dispatch(setWalletAddress(walletAddress))
      const signResult = await sdk?.connectAndSign({
        msg: `this is a web3 test message`,
      });

      if (signResult) {
        console.log("Signed message token:", signResult);
        dispatch(setSignedToken(signResult))
        dispatch(setConnected(true))
        authorise(signResult as string, walletAddress)
      }
    } catch (err) {
      console.warn("Failed to connect or sign message:", err);
    }
  };

  function authorise(signedToken: string, walletAddress: string) {
    const headers = {
      'X-SIG': signedToken,
      'X-WAL': walletAddress,
      'X-MES': 'this is a web3 test message',
    };

    fetch(window.location.origin + "/mv", {
      method: 'GET', 
      headers: headers,
    })
      .then((response) => {
        fetchData(window.location.origin + "/user-profile", signedToken, walletAddress)
        .then(data => {
          console.log('Data received:', data);
    
          dispatch(setPlayer(data))
          if (data.glbUrl) {
            let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
            window.avatarId = modelId
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); 
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  }


  async function fetchData(url: string,signedToken: string, walletAddress: string): Promise<any> {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-SIG': signedToken,
          'X-WAL': walletAddress,
          'X-MES': 'this is a web3 test message',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  return (
    <div style={boxStyle}>
      <CssBaseline />
      <AppBar position="fixed" component="nav" color="transparent" style={{ boxShadow: 'none', color: 'white', padding: '0 100px' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Left Section: Menu Icon and Nav Items */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
            <button className="join-button" onClick={connectAndSign} disabled={connected}>
              {connected ? "Connected": "Login with MetaMask"}
            </button>
          </div>

          {/* Right Section: Search Input and Login Button */}
          <div style={{ display: connected ? 'flex':"none", alignItems: 'center' }}>
            <Button color="inherit" sx={{ fontSize: '1.3rem', fontWeight: 'bold', marginLeft: '10px' }} onClick={() => {
              handleDisconnect()
            }}>
              Disconnect
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'black', color: 'white' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
};
