import { useEffect, useState } from "react"
import "./Settings.css"
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from "../../store/hooks";


type Props = {
    iFramePostMessage: Function
}
export const SettingsWindow: React.FC<Props> = ({iFramePostMessage}) => {

    const [volume, setVolume] = useState(1)
    const [stepValue, setStepValue] = useState(1);
    const [showSettings,setShowSettings] = useState(false);
    const { player } = useAppSelector(state => state.appUser)

    const handleGraphics = (setting: number) => {
        let data = {
            settings:{
                ActiveGraphicsPreset: setting
            }
        }
        iFramePostMessage("ReactClientManager", "SetUserSetting", JSON.stringify(data))
          
    }

    const handleMouseSensitivity = (setting: number) => {
        let data = {
            settings: {
                "Input_MouseSensitivity": setting
            }
        }
        iFramePostMessage("ReactClientManager", "SetUserSetting", JSON.stringify(data))
    }

    const handleVolume = (setting: number) => {
        let data ={
            settings: {
                "Audio_Volume": setting
            }
        }
        console.log("JSON LOG",JSON.stringify(data))
        iFramePostMessage("ReactClientManager", "SetUserSetting", JSON.stringify(data))
    }

    useEffect(() => {
        player.userPreferences?.entries.forEach(entry => {
            if(entry.identifier === "settings"){
                if(entry.data!=null){
                    if(entry.data["Audio_Volume"]){
                        setVolume(entry.data["Audio_Volume"])
                    }
                    if(entry.data["Input_MouseSensitivity"]){
                        setStepValue(entry.data["Input_MouseSensitivity"])
                    }
                }
            }
        })
    },[])

    return (
        <><button
            className="open-settings-button"
            onClick={() => setShowSettings(!showSettings)}
        ><SettingsIcon/></button>
        
        { showSettings && (<div className="settings-modal">
            <div className="settings" >
                <div onClick={() => setShowSettings(false)} style={{position: 'absolute', top: '15px', right: '15px', cursor: 'pointer'}}><CloseIcon /></div>
                <h2>Settings</h2>

                <div className="settings-section">
                    <h3>Volume</h3>
                    <input
                        type="range"
                        min={0}
                        max={1}
                        step={0.1}
                        value={volume}
                        onChange={(e) => {
                            setVolume(Number(e.target.value))
                            handleVolume(Number(e.target.value))
                        }}
                        className="slider" />
                </div>
                <div className="settings-section">
                    <h3>Graphics Quality</h3>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'center', alignItems:'center', width: '100%' }}>
                        <button className="settings-button" onClick={() => handleGraphics(0)}>Low</button>
                        <button className="settings-button" onClick={() => handleGraphics(1)}>Medium</button>
                        <button className="settings-button" onClick={() => handleGraphics(2)}>High</button>
                    </div>
                </div>
                <div className="settings-section">
                    <h3>Mouse Sensitivity</h3>
                    <input
                        type="range"
                        min={1}
                        max={5}
                        step={1}
                        value={stepValue}
                        onChange={(e) => {
                            setStepValue(Number(e.target.value))
                            handleMouseSensitivity(Number(e.target.value))
                        }}
                        className="slider" />
                </div>
            </div>

        </div>)}</>)
}