import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  element: ReactElement;
  loggedIn: boolean;
};

const ProtectedRoute = ({ element, loggedIn }: ProtectedRouteProps): ReactElement | null => {
  console.log(loggedIn)
  return loggedIn ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
