import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

type Props = {
  count: number;
  className: string;
};

export const OnlineTracker: React.FC<Props> = ({ count, className }) => {
  return (
    <div className={`online-tracker ${className}`}>
      <p className="tracker-text">
        <span className="icon-container">
          <PersonIcon />
        </span>
        {count} Users Online
      </p>
    </div>
  );
};
