import React, { useEffect, useState } from "react";
import { VRMViewer } from "./VRMViewer";
import { VRMPicker } from "./VRMPicker";
import { useAppSelector } from "../../store/hooks";

type Props = {
    setCanJumpIn: Function;
    currentClassName: string;
    modal: boolean;
    show?: boolean;
    setShow?: Function;
}
export const VRM:React.FC<Props> = ({setCanJumpIn, currentClassName, modal, show, setShow}) => {

    const [vrmUrl, setVrmUrl] = useState("");
    const { player} = useAppSelector(state =>  state.appUser)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if(player.glbUrl?.endsWith(".vrm")){
            setVrmUrl(player.glbUrl)
        }
    },[])

    if(modal){
        return(<div className="vrm-container-modal">
            <div className="vrm-container-inner" style={{display: show ? "flex" : "none"}}>
                <div className="vrm-viewer-container">
                <VRMViewer vrmUrl={vrmUrl} setShowLoader={setShowLoader} />
                </div>
                <div className="vrm-picker-container">
                <VRMPicker 
                    setCanJumpIn={setCanJumpIn} 
                    currentClassName={currentClassName} 
                    setVrmUrl={setVrmUrl} 
                    vrmUrl={vrmUrl}
                    show={show}
                    setShow={setShow}
                />
                </div>
        </div>
        {showLoader  && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 5006436554365436 }}>
                    <span className="loader-rpm"></span>
                </div>
            )}
        </div>
        )
    }
    return(
        <div className="vrm-container">
            <div className="overlay-hero" style={{ zIndex: 0}}></div>
            <div className="vrm-container-inner">
                <div className="vrm-viewer-container">
                <VRMViewer vrmUrl={vrmUrl} setShowLoader={setShowLoader}/>
                </div>
                <div className="vrm-picker-container">
                <VRMPicker 
                    setCanJumpIn={setCanJumpIn} 
                    currentClassName={currentClassName} 
                    setVrmUrl={setVrmUrl} 
                    vrmUrl={vrmUrl}
                />
                </div>
        </div>
        {showLoader  && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 5006436554365436 }}>
                    <span className="loader-rpm"></span>
                </div>
            )}
      </div>
    )
}