import { createSlice} from "@reduxjs/toolkit";
import { StaticReadUsage } from "three";

interface userMessage {
    guid: string,
    mesg: string, 
    timestamp: string
}

interface preference {
    identifier: any;
    data: any;
}

interface entries{
    entries: preference[];
}

export interface user {
    displayName: string | null,
    glbUrl: string | null,
    avatarProfilePic: string | null,
    disabled: boolean,
    hashedEmail: string,
    created: number,
    createdOffset: string,
    createdZone: string,
    authToken: string,
    playerId: string,
    colour: string,
    createdByRpmAccount: boolean,
    userPreferences: entries | null,
    peer_id: string
}

interface AppUserState {
    chatHandle: string,
    userMessages: userMessage[],
    player: user,
    id: string,
    update: boolean,
    sessionId: string,
    isInParty: boolean,
    rpmId: string,
    rpmBearerToken: string,
    avatarGender: "male" | "female",
    avatarId: string 
    loggedIntoRPM: boolean,
    hideUseCurrentButton: boolean,
    needsLoginFlag: boolean,
    cookieDoesntMatchFlag: boolean,
    canJumpIn: boolean,
    JWT: string,
    showAlert: boolean
}

const initialState: AppUserState = {
    chatHandle: "",
    userMessages: [],
    player: {
        displayName: null,
        glbUrl: null,
        avatarProfilePic: null,
        disabled: false,
        hashedEmail: "",
        created: -1,
        createdOffset: "",
        createdZone: "",
        authToken: "",
        playerId: "",
        colour: "#fff",
        createdByRpmAccount: false,
        userPreferences: null,
        peer_id: ""
    },
    id: "",
    update: false,
    sessionId: "",
    isInParty: false,
    rpmId: "",
    rpmBearerToken: "",
    avatarGender: "male",
    avatarId: "",
    loggedIntoRPM: false,
    hideUseCurrentButton: false,
    needsLoginFlag: false,
    cookieDoesntMatchFlag: false,
    canJumpIn: false,
    JWT: "",
    showAlert: false
}
export const appUserSlice = createSlice({
    name:"appUser",
    initialState,
    reducers: {
        setUserMessages: (state, action) => {
            state.userMessages = action.payload
        },
        setChatHandle: (state, action) => {
            state.chatHandle = action.payload
        },
        setPlayer: (state, action) => {
            state.player = action.payload
        },
        setId: (state, action) => {
            state.id = action.payload
        },
        setUpdate: (state, action) => {
            state.update = action.payload
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        },
        setIsInParty: (state, action) => {
            state.isInParty = action.payload
        },
        setRpmId: (state, action) => {
            state.rpmId = action.payload
        },
        setRpmBearerToken: (state, action) => {
            state.rpmBearerToken = action.payload
        },
        setAvatarGender: (state, action) => {
            state.avatarGender = action.payload
        },
        setAvatarId: (state, action) => {
            state.avatarId = action.payload
        },
        setLoggedIntoRPM: (state, action) => {
            state.loggedIntoRPM = action.payload
        },
        sethideUseCurrentButton: (state, action) => {
            state.hideUseCurrentButton = action.payload
        },
        setNeedsLoginFlag: (state, action) => {
            state.needsLoginFlag = action.payload
        },
        setCookieDoesntMatchFlag: (state, action) => {
            state.cookieDoesntMatchFlag = action.payload
        },
        setCanJumpIn: (state, action) => {
            state.canJumpIn = action.payload
        },
        setJWT: (state, action) => {
            state.JWT = action.payload
        },
        setShowAlert: (state, action) => {
            state.showAlert = action.payload
        }
    }
});

export const { 
    setId, 
    setUserMessages,
    setChatHandle, 
    setPlayer, 
    setUpdate, 
    setSessionId, 
    setIsInParty, 
    setRpmId, 
    setRpmBearerToken, 
    setAvatarGender, 
    setAvatarId, 
    setLoggedIntoRPM,
    sethideUseCurrentButton,
    setNeedsLoginFlag,
    setCookieDoesntMatchFlag,
    setCanJumpIn,
    setJWT,
    setShowAlert
 } = appUserSlice.actions;

export default appUserSlice.reducer;