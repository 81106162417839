import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter } from "react-router-dom";
import { Provider} from "react-redux";
import {store} from './store/store';
import { MetaMaskProvider } from "@metamask/sdk-react"
import { setShowAlert } from './store/appUser';


const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    error: {
      main: 'rgb(220, 255,0)'
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MetaMaskProvider
      sdkOptions={{
        dappMetadata: {
          name: "React dApp",
          url: window.location.href,
        }
      }}
    >
    <Provider store={store}>
      <BrowserRouter basename='/'>
        <ThemeProvider theme={theme} >
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
    </MetaMaskProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
