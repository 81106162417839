import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { VRM, VRMHumanBoneName, VRMLoaderPlugin } from '@pixiv/three-vrm';
import { OrbitControls } from '@react-three/drei';

type Props = {
  vrmUrl: string;
  setShowLoader: Function;
};
// This map translates Mixamo bone names to their equivalent VRM humanoid bone names
const mixamoVRMRigMap: Record<string, string> = {
  mixamorigHips: 'hips',
  mixamorigSpine: 'spine',
  mixamorigSpine1: 'chest',
  mixamorigSpine2: 'upperChest',
  mixamorigNeck: 'neck',
  mixamorigHead: 'head',
  // Left arm
  mixamorigLeftShoulder: 'leftShoulder',
  mixamorigLeftArm: 'leftUpperArm',
  mixamorigLeftForeArm: 'leftLowerArm',
  mixamorigLeftHand: 'leftHand',
  // Left fingers
  mixamorigLeftHandThumb1: 'leftThumbMetacarpal',
  mixamorigLeftHandThumb2: 'leftThumbProximal',
  mixamorigLeftHandThumb3: 'leftThumbDistal',
  mixamorigLeftHandIndex1: 'leftIndexProximal',
  mixamorigLeftHandIndex2: 'leftIndexIntermediate',
  mixamorigLeftHandIndex3: 'leftIndexDistal',
  mixamorigLeftHandMiddle1: 'leftMiddleProximal',
  mixamorigLeftHandMiddle2: 'leftMiddleIntermediate',
  mixamorigLeftHandMiddle3: 'leftMiddleDistal',
  mixamorigLeftHandRing1: 'leftRingProximal',
  mixamorigLeftHandRing2: 'leftRingIntermediate',
  mixamorigLeftHandRing3: 'leftRingDistal',
  mixamorigLeftHandPinky1: 'leftLittleProximal',
  mixamorigLeftHandPinky2: 'leftLittleIntermediate',
  mixamorigLeftHandPinky3: 'leftLittleDistal',
  // Right arm
  mixamorigRightShoulder: 'rightShoulder',
  mixamorigRightArm: 'rightUpperArm',
  mixamorigRightForeArm: 'rightLowerArm',
  mixamorigRightHand: 'rightHand',
  // Right fingers
  mixamorigRightHandThumb1: 'rightThumbMetacarpal',
  mixamorigRightHandThumb2: 'rightThumbProximal',
  mixamorigRightHandThumb3: 'rightThumbDistal',
  mixamorigRightHandIndex1: 'rightIndexProximal',
  mixamorigRightHandIndex2: 'rightIndexIntermediate',
  mixamorigRightHandIndex3: 'rightIndexDistal',
  mixamorigRightHandMiddle1: 'rightMiddleProximal',
  mixamorigRightHandMiddle2: 'rightMiddleIntermediate',
  mixamorigRightHandMiddle3: 'rightMiddleDistal',
  mixamorigRightHandRing1: 'rightRingProximal',
  mixamorigRightHandRing2: 'rightRingIntermediate',
  mixamorigRightHandRing3: 'rightRingDistal',
  mixamorigRightHandPinky1: 'rightLittleProximal',
  mixamorigRightHandPinky2: 'rightLittleIntermediate',
  mixamorigRightHandPinky3: 'rightLittleDistal',
  // Legs
  mixamorigLeftUpLeg: 'leftUpperLeg',
  mixamorigLeftLeg: 'leftLowerLeg',
  mixamorigLeftFoot: 'leftFoot',
  mixamorigLeftToeBase: 'leftToes',
  mixamorigRightUpLeg: 'rightUpperLeg',
  mixamorigRightLeg: 'rightLowerLeg',
  mixamorigRightFoot: 'rightFoot',
  mixamorigRightToeBase: 'rightToes',
};

type AnimationSource = {
  scene: THREE.Group;                    // Source FBX scene (with Mixamo rig)
  animations: THREE.AnimationClip[];    // Source animation clips from Mixamo
};

type CharacterTarget = {
  vrm: VRM;                              // Target VRM model to retarget onto
};

function convertFbxToVrmAnimation(
  animationGlb: AnimationSource,
  characterGlb: CharacterTarget
): THREE.AnimationClip | null {
  const clip = animationGlb.animations[0];
  if (!clip) return null; // No animation found

  const vrm = characterGlb.vrm;
  const asset = animationGlb.scene;
  const tracks: THREE.KeyframeTrack[] = []; // New tracks we'll build for the VRM

  // Ensure the source animation rig has "mixamorigHips" (a sign it's Mixamo)
  if (asset.getObjectByName('mixamorigHips')) {
    // Temp variables for quaternion and vector math
    const restRotationInverse = new THREE.Quaternion();
    const parentRestWorldRotation = new THREE.Quaternion();
    const _quatA = new THREE.Quaternion();
    const _quatB = new THREE.Quaternion();
    const _vec3 = new THREE.Vector3();

    // Compute scale to match Mixamo hip height with VRM hip height
    const motionHipsHeight = asset.getObjectByName('mixamorigHips')!.position.y;
    const vrmHipsY = vrm.humanoid?.getNormalizedBoneNode('hips')?.getWorldPosition(_vec3).y ?? 0;
    const vrmRootY = vrm.scene.getWorldPosition(_vec3).y;
    const vrmHipsHeight = Math.abs(vrmHipsY - vrmRootY);
    const hipsPositionScale = vrmHipsHeight / motionHipsHeight;

    // Loop through each track in the Mixamo animation
    for (const track of clip.tracks) {
      const [mixamoName, propertyName] = track.name.split('.');
      const vrmBoneName = mixamoVRMRigMap[mixamoName];

      // Get VRM nodes for this bone
      const vrmNormalizedNode = vrm.humanoid?.getNormalizedBoneNode(vrmBoneName as VRMHumanBoneName);
      const vrmRawNode = vrm.humanoid?.getRawBoneNode(vrmBoneName as VRMHumanBoneName);
      if (!vrmRawNode || !vrmNormalizedNode) continue;

      const rawName = vrmRawNode.name;

      const mixamoRigNode = asset.getObjectByName(mixamoName);
      if (!mixamoRigNode || !mixamoRigNode.parent) continue;

      // Get rest pose rotation of Mixamo bone and its parent
      mixamoRigNode.getWorldQuaternion(restRotationInverse).invert();
      mixamoRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

      // If it's a rotation track (Quaternion)
      if (track instanceof THREE.QuaternionKeyframeTrack) {
        const newTrackValues = new Float32Array(track.values.length);

        for (let i = 0; i < track.values.length; i += 4) {
          // Get current rotation keyframe
          _quatA.fromArray(track.values, i);

          // Reorient from Mixamo local space to world space
          _quatA
            .premultiply(parentRestWorldRotation)
            .multiply(restRotationInverse);

          // Retarget into VRM local bone space
          const hr = (vrm.humanoid as any)._normalizedHumanBones;
          const parentWorldRotation = hr._parentWorldRotations[vrmBoneName];
          const invParentWorldRotation = _quatB.copy(parentWorldRotation).invert();
          const boneRotation = hr._boneRotations[vrmBoneName];

          _quatA
            .multiply(parentWorldRotation)
            .premultiply(invParentWorldRotation)
            .multiply(boneRotation);

          // Save transformed rotation back
          _quatA.toArray(newTrackValues, i);
        }

        // Add the converted track, with coordinate fix for VRM 0.0
        tracks.push(
          new THREE.QuaternionKeyframeTrack(
            `${rawName}.${propertyName}`,
            track.times,
            newTrackValues.map((v, i) =>
              vrm.meta?.metaVersion === '0' && i % 2 === 0 ? -v : v
            )
          )
        );
      }
      // If it's a position track (usually only for hips)
      else if (track instanceof THREE.VectorKeyframeTrack) {
        const newValues = track.values.map(
          (v, i) =>
            (vrm.meta?.metaVersion === '0' && i % 3 !== 1 ? -v : v) *
            hipsPositionScale // Apply Y-axis scale to match VRM hip height
        );

        tracks.push(
          new THREE.VectorKeyframeTrack(
            `${rawName}.${propertyName}`,
            track.times,
            newValues
          )
        );
      }
    }
  }

  // Return the new animation clip retargeted to VRM
  const newClip = new THREE.AnimationClip('vrmAnimation', clip.duration, tracks);
  return newClip;
}


const VRMModel: React.FC<{ vrmUrl: string, setShowLoader:Function }> = ({ vrmUrl, setShowLoader }) => {
  const { camera } = useThree();
  const [isReady, setIsReady] = useState(false);
  const loaderRef = useRef<GLTFLoader>();
  const mixerRef = useRef<THREE.AnimationMixer>();
  const [vrm, setVrm] = useState<VRM | null>(null);

  useEffect(() => {
    setIsReady(false)
    setShowLoader(true)
  },[vrmUrl])

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.register((parser) => new VRMLoaderPlugin(parser));
    loaderRef.current = loader;

    loader.load(vrmUrl, (gltf) => {
      const loadedVrm = gltf.userData.vrm as VRM;
      loadedVrm.scene.rotation.y = Math.PI;
      setVrm(loadedVrm);
      loadAnimation(loadedVrm);
    });
  }, [vrmUrl]);

  useEffect(() => {
    if (vrm?.lookAt) {
      vrm.lookAt.target = camera;
    }
  }, [vrm, camera]);

  useFrame((_, delta) => {
    if (mixerRef.current) {
      mixerRef.current.update(delta);
    }
  });

  function loadAnimation(vrm: VRM) {
    const fbxLoader = new FBXLoader();
    fbxLoader.load(
      '/animations/Idle.fbx',
      (fbx) => {
        const mixer = new THREE.AnimationMixer(vrm.scene);
        mixerRef.current = mixer;

        const retargetedClip = convertFbxToVrmAnimation(
          { scene: fbx, animations: fbx.animations },
          { vrm }
        );
        
        if (retargetedClip) {
          mixer.clipAction(retargetedClip).play();
          setIsReady(true); 
          setShowLoader(false);
        }
      },
      undefined,
      (err) => console.error('Failed to load FBX:', err)
    );
  }

  return vrm && isReady ? <primitive object={vrm.scene} /> : null;

};

export const VRMViewer: React.FC<Props> = ({ vrmUrl,setShowLoader }) => {
  return (
    <div className="vrm-viewer-container">
      <Canvas
      className="vrm-viewer-canvas-container"
      camera={{ position: [0, 1.7, 4.2], fov: 35 }}
    >
      <ambientLight intensity={5} />
      <VRMModel vrmUrl={vrmUrl} setShowLoader={setShowLoader} />
      <OrbitControls
        target={[0, 0.85, 0]}     
        minDistance={2.5}
        maxDistance={5}
        enablePan={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
      />
    </Canvas>
        
    </div>
  );
};
