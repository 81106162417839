import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setPlayer, user } from "../../store/appUser";
import { useNavigate } from "react-router-dom";

type VRM = {
    name: string;
    description: string;
    url: string;
    image: {
        cachedUrl: string;
        thumbnailUrl: string;
        contentType: string;
        size: string;
    }
}

type Props = {
    setCanJumpIn: Function;
    currentClassName: string;
    setVrmUrl: Function;
    vrmUrl: string;
    show?: boolean;
    setShow?: Function;
}
export const VRMPicker: React.FC<Props> =  ({setCanJumpIn, currentClassName, setVrmUrl, vrmUrl, show, setShow}) => {

    //const {walletAddress, signedToken} = useAppSelector(state => state.web3) 
    const { player } = useAppSelector(state => state.appUser)
    const [myVrm, setMyvrm] = useState<VRM[]>([])
    const [publicVrm, setPublicVrm] = useState<VRM[]>([])
    const [vrmImage, setVrmImage] = useState("")
    const dispatch = useAppDispatch()
    const history = useNavigate()

    useEffect(() => {
        const headers = {
            'X-SIG': "0x1d43ed8b8df9eada9cde7cde868a515fc7fe8a7d3cccb6eaa2f32cf96c8696771005fb8b72f2565fe2e928ca4e7029a95ad405a866ba4e9166288f4c7efde0dc1c",
            'X-WAL': "0xe5cf1bb88a59f9fc609689c681d1d14bfe7ce73a",
            'X-MES': 'this is a web3 test message',
        };
    
        fetch(process.env.REACT_APP_VRM_SERVER + "/api/myvrm", {
            method: 'GET', 
            headers: headers,
        })
        .then(res => res.json())  
        .then(data => {
            console.log(data.vrms);  
            
            setMyvrm(data.vrms)
           
        })
        .catch(error => {
            console.error("Error fetching VRM data:", error);
        });
    }, []);

    useEffect(() => {
        const headers = {
            'X-SIG': "0x1d43ed8b8df9eada9cde7cde868a515fc7fe8a7d3cccb6eaa2f32cf96c8696771005fb8b72f2565fe2e928ca4e7029a95ad405a866ba4e9166288f4c7efde0dc1c",
            'X-WAL': "0xe5cf1bb88a59f9fc609689c681d1d14bfe7ce73a",
            'X-MES': 'this is a web3 test message',
          };
        fetch(process.env.REACT_APP_VRM_SERVER + "/api/publicvrm", {
            method: 'GET', 
            headers: headers,
          }) .then(res => res.json())  
          .then(data => {
              console.log(data.vrms);  
              setPublicVrm(data.vrms);
              setVrmUrl(data.vrms[0].url)
              setVrmImage(data.vrms[0].image.thumbnailUrl)
          })
          .catch(error => {
              console.error("Error fetching VRM data:", error);
          });
    },[])

    const handleJumpIn = (url: string, image: string, playerCurrent: user) => {
        let dataUpdate = {
            ...playerCurrent,
            glbUrl: url,
            avatarProfilePic: image,
            createdByRpmAccount: false
        }
        dispatch(setPlayer(dataUpdate))
       
        if(show && setShow){
            if(url.length > 0){
                let data = {
                    displayName: player.displayName,
                    avatarUrl: url,
                    avatarProfilePic: image,
                    colour: player.colour,
                    createdByRpmAccount: false
                }
                if(window.SendMessage)
                window.SendMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data));
            }
            setShow(false)
        } else {
            setCanJumpIn(true)
            history("/mv/live/" + currentClassName)
        }
       
    }

    return(
        <div >
        <div className="vrm-picker-content">
            <h3>My VRMS</h3>
            <div className="vrm-list">
                {myVrm.map((vrm) => (
                    <div
                        className="vrm-item"
                        key={vrm.url}
                        onClick={() => {
                            setVrmImage(vrm.image.thumbnailUrl)
                            setVrmUrl(vrm.url)}}
                    >
                        <img src={vrm.image.thumbnailUrl} alt={vrm.name} height={'30%'}/>
                    </div>
                ))}
            </div>
            <hr />
            <h3>Public VRMS</h3>
            <div className="vrm-list">
                {publicVrm.map((vrm) => (
                    <div
                        className="vrm-item"
                        key={vrm.url}
                        onClick={() => {
                            setVrmImage(vrm.image.thumbnailUrl)
                            setVrmUrl(vrm.url)}}
                    >
                        <img src={vrm.image.thumbnailUrl} alt={vrm.name} />
                    </div>
                ))}
            </div>
        </div>
        <button
            className="button-85 vrm-finish-button"
            onClick={() => handleJumpIn(vrmUrl, vrmImage, player)}
        >Finish</button>
    </div>
    )
}