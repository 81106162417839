import { configureStore,  } from "@reduxjs/toolkit";
import appUserReducer from './appUser';
import ticketPurchaseReducer from "./ticketPurchase";
import messagesReducer from './messages';
import debug from "./debug";
import usersOnline from "./usersOnline";
import scenes from "./scenes";
import party from "./party";
import web3 from "./web3"

export const store = configureStore({
    reducer: {
        appUser: appUserReducer,
        ticketPurchases: ticketPurchaseReducer,
        messages: messagesReducer,
        debug: debug,
        usersOnline: usersOnline,
        scene: scenes,
        party: party,
        web3: web3
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
