import { useState, useEffect } from "react";
import { globalIsHost } from "./InboundLinkRouting";

type Props = {
    frame:HTMLIFrameElement | null
}
export const PartiesUi: React.FC<Props> = ({frame}) => {

    const [display, setDisplay] = useState(false)

    useEffect(() => {
        if (frame) {
            console.log("There is a Frame");
        
            const applyStyles = () => {
                console.log("Applying styles to the loaded frame");
                const newStyleElement = document.createElement("style");
                
                const iframe = frame as HTMLIFrameElement; // Cast frame to HTMLIFrameElement
                  if (iframe.contentDocument) {
                    const linkElement = iframe.contentDocument.createElement("link");
                    linkElement.rel = "stylesheet";
                    linkElement.type = "text/css";
                    linkElement.href = "/Parties.css"; 
                    iframe.contentDocument.head.appendChild(linkElement);
        
                    console.log("Stylesheet applied to iframe");
                }
            };
        
                applyStyles();
            } 
        
    }, [frame]);

    useEffect(() => {
        let host = globalIsHost.isHost
        if(host){
            window.isHost = true
            setDisplay(true)
        }
    })

    
    

    return (
        <> </>
    );
}