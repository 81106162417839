import * as React from 'react';
import Box from '@mui/material/Box';
import { VRM } from './VRM';

export type StyleType = {
  position: string;
  top: string;
  left: string;
  transform: string;
  width: string;
  boxShadow: number;
  p: number;
};

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  setOpen: Function;
  setCanJumpIn: Function;
  modal: boolean;
  currentClassName: string;
}
export const VRMUpdateModal: React.FC<Props> = ({ 
  open, 
  setOpen, 
setCanJumpIn,
modal,
currentClassName
}) => {

  return (
    <div style={{ display: open ? "flex" : "none", zIndex: 5, width: '100%' }}>
      <Box component="div" className='profile-modal' sx={style}>
        <VRM setCanJumpIn={setCanJumpIn} setShow={setOpen} show={open} modal={modal} currentClassName={currentClassName}/>
      </Box>
    </div>
  );
}