import * as React from 'react';
import { RPM } from '@vc/rpm-custom';
import { VRM } from './VRM/VRM';
import { Scene } from '../store/scenes';
import { setCookieDoesntMatchFlag, setPlayer, setCanJumpIn, setRpmBearerToken, setRpmId, setAvatarId } from '../store/appUser';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setSelectedPlayer } from '../store/party';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  );
};

type Props = {
  scene: Scene;
  setHasCookie: Function;
};

export const AvatarSwitcher: React.FC<Props> = ({ scene, setHasCookie }) => {
  
  const { player, canJumpIn, needsLoginFlag, cookieDoesntMatchFlag, rpmBearerToken, rpmId, avatarId } = useAppSelector(state => state.appUser);
  const [value, setValue] = React.useState(player.glbUrl?.includes(".vrm") ? 1 : 0);
  const { className } = useAppSelector(state => state.scene);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const RPMConfig = {
    needsLoginFlag: needsLoginFlag,
    cookieDoesntMatchFlag: cookieDoesntMatchFlag,
    rpmBearerToken: rpmBearerToken,
    rpmId: rpmId,
    avatarId: avatarId,
    currentClassName: className,
    setCookieDoesntMatchFlag: setCookieDoesntMatchFlag,
    setPlayer: setPlayer,
    setCanJumpIn: setCanJumpIn,
    setSelectedPlayer: setSelectedPlayer,
    setRpmBearerToken: setRpmBearerToken,
    setRpmId: setRpmId,
    setAvatarId: setAvatarId,
    dispatch: dispatch,
  };
  const handleTabClick = (index: number) => {
    setValue(index);
  };

  return (
    <div>
      <div className="tabs">
        <div
          className="join-button"
          onClick={() => handleTabClick(0)}
        >
          ReadyPlayerMe
        </div>
        <div
          className="join-button"
          onClick={() => handleTabClick(1)}
        >
          VRM
        </div>
      </div>

      <CustomTabPanel value={value} index={0}>
        <RPM
          player={player}
          config={RPMConfig}
          backpack={false}
          avatarBackgroundColour={'rgb(36, 30, 54)'}
          titles={false}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <VRM setCanJumpIn={setHasCookie} currentClassName={scene.className} modal={false}/>
      </CustomTabPanel>

    </div>
  );
};
