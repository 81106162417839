import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { useAppDispatch } from '../store/hooks';
import { setShowAlert } from '../store/appUser';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
  };

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  boxShadow: 24,
  p: 4,
};

type Props = {
    open: boolean
}
export const AlertModal: React.FC<Props> = ({open}) =>  {
  
    const dispatch = useAppDispatch()
    const handleClose = () =>  dispatch(setShowAlert(false));
 

  return (
    <div >
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          zIndex: 10000000, 
        }}
      >
        <Fade in={open}>
          <Box component="div" className='RPMLogin' sx={style}>
            <div style={{ backgroundColor: 'black', padding: '30px', color: 'white', textAlign: 'center'}}>
            <h4>Wallet Change Detected</h4>
            <p>You Have Been Logged Out Please Reconnect And Sign The Message With This New Wallet</p>
            </div>
            
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
  